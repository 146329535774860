<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="name" label="Student">
        <template slot-scope="scope">
          <b class="vip" v-if="scope.row.vip === 1">
            <i class="fas fa-crown"></i>
          </b>
          <router-link
            class="stretched-link"
            :to="transcriptRoute(scope.row.user_exam_id)"
          >
            {{ userMethods.displayName(scope.row.first_name, scope.row.last_name) }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="Grading Type">
        <template slot-scope="scope">
          <b
            :class="scope.row.vip === 1 ? 'vip' : ''"
            v-if="scope.row.can_suggest === 0"
          >
            Scores Only
          </b>
          <template v-else>
            <!-- Detail Comments -->
            <b
              :class="scope.row.vip === 1 ? 'vip' : ''"
              v-if="scope.row.suggest_type === 'detail'"
            >
              <i class="fas fa-crown"></i>
              VIP Comments
            </b>
            <!-- Simple Comments -->
            <b
              :class="scope.row.vip === 1 ? 'vip' : ''"
              v-if="scope.row.suggest_type === 'outline'"
            >
              Simple Comments
            </b>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Student">
        <template slot-scope="scope">
          {{ userMethods.displayName(scope.row.first_name, scope.row.last_name) }}
        </template>
      </el-table-column>
      <el-table-column prop="total_score" label="Score">
        <template slot-scope="scope">
          <b v-if="scope.row.total_score > 0">
            {{ scope.row.total_score }}
          </b>
          <div v-else>
            <el-tag size="medium" type="warning">Pending</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="tradable_created_at"
        label="Request Date"
        width="200px"
      >
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.tradable_created_at) }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Start Date">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column v-if="false" label="Actions" fixed="right" width="130">
        <el-button type="text" class="text-danger" size="small">
          <i class="fas fa-trash-alt"></i> 刪除
        </el-button>
        <el-button type="text" size="small">
          <i class="fas fa-edit"></i> 编辑
        </el-button>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { instant, user } from "@ivy-way/material";

export default {
  props: ["tableData", "isAdmin", "graded"],
  computed: {
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  },
  methods: {
    transcriptRoute(id) {
      return {
        name: "toeflResult",
        query: { id }
      };
    }
  }
};
</script>

<style scoped>
.updateScore {
  font-size: 18px;
}
.vip {
  color: #f0ad4e;
}
::v-deep tr.el-table__row {
  cursor: pointer;
  position: relative;
}

::v-deep .el-table__row > td {
  position: static;
}

.stretched-link {
  color: #606266 !important;
  text-decoration: none;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
}
</style>
